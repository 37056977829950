import React, { FunctionComponent } from "react";
import { RouteComponentProps } from "@reach/router";
import GatsbyImage, { FluidObject } from "gatsby-image";

import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby-plugin-intl";

import Layout from "../components/Layout";

import styles from "../styles/pages/registo.module.scss";
import TextCard from "../components/TextCard";
import Spacer from "../components/Spacer";
import RedFeature from "../components/RedFeature";
import ContactForm from "../components/ContactForm";
import Button from "../components/Button";
import Flex from "../components/Flex";
import { useIntl } from "gatsby-plugin-intl";
import SEO from "../components/SEO";

const AboutPage: FunctionComponent<RouteComponentProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      backgroundSplash: file(
        relativePath: { eq: "backgrounds/take-eat-hero.jpg" }
      ) {
        publicURL
        childImageSharp {
          fluid(quality: 90) {
            src
            tracedSVG
          }
        }
      }
      starIcon: file(relativePath: { eq: "icons/starWhite.svg" }) {
        publicURL
      }
      house: file(relativePath: { eq: "icons/houseTakeEat.svg" }) {
        publicURL
      }
      ipad: file(relativePath: { eq: "backgrounds/ipad.svg" }) {
        publicURL
      }
      backgroundContact: file(
        relativePath: { eq: "backgrounds/BackgroundContactRegister.svg" }
      ) {
        publicURL
      }
      yellowSquares: file(
        relativePath: { eq: "backgrounds/yellow-squares.svg" }
      ) {
        publicURL
      }
      orderBadge: file(relativePath: { eq: "badges/order.svg" }) {
        publicURL
      }
      stockBadge: file(relativePath: { eq: "badges/stock.svg" }) {
        publicURL
      }
      bellBadge: file(relativePath: { eq: "badges/bell.svg" }) {
        publicURL
      }
      menuBadge: file(relativePath: { eq: "badges/menu.svg" }) {
        publicURL
      }
    }
  `);

  const renderAdvantagesCards = (advantages) => {
    return advantages.map((item) => (
      <div className={styles.advantageCardWrapper}>
        <TextCard>
          <div className={styles.card}>
            <h3 className={styles.cardTitle}>
              {intl.formatMessage({ id: item.title })}
            </h3>
            <p className={styles.cardText}>
              {intl.formatMessage({ id: item.subtitle })}
            </p>
          </div>
        </TextCard>
      </div>
    ));
  };

  const intl = useIntl();

  const advantages = [
    {
      title: "registerPage_advantage_feature_one_title",
      subtitle: "registerPage_advantage_feature_one_subTitle",
    },
    {
      title: "registerPage_advantage_feature_two_title",
      subtitle: "registerPage_advantage_feature_two_subTitle",
    },
    {
      title: "registerPage_advantage_feature_three_title",
      subtitle: "registerPage_advantage_feature_three_subTitle",
    },
    {
      title: "registerPage_advantage_feature_four_title",
      subtitle: "registerPage_advantage_feature_four_subTitle",
    },
    {
      title: "registerPage_advantage_feature_five_title",
      subtitle: "registerPage_advantage_feature_five_subTitle",
    },
    {
      title: "registerPage_advantage_feature_six_title",
      subtitle: "registerPage_advantage_feature_six_subTitle",
    },
  ];
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "seo_title_registo" })}
        description={intl.formatMessage({ id: "seo_description_registo" })}
        keywords={intl.formatMessage({ id: "seo_keywords_registo" })}
        canonical_url={`https://take-eat.pt/registo`}
        image={`https://take-eat.pt${data.ipad?.publicURL}`}
      />
      <div className={styles.registerWrapper}>
        <div
          // style={{
          //   backgroundImage: "url(" + data.backgroundSplash.publicURL + ")",
          // }}
          className={styles.splashImageSection}
        >
          <div className={styles.floaterContainer}>
            <GatsbyImage
              fadeIn
              alt=""
              fluid={data.backgroundSplash?.childImageSharp.fluid}
              loading="lazy"
              style={{
                width: "100%",
                height: "100%",
                zIndex: -1,
                margin: 0,
              }}
            />
          </div>
          <div className={styles.floaterContainer} style={{ top: "-100%" }}>
            <div id={styles.text}></div>
            <h1 className={styles.partnerTitle}>
              {intl.formatMessage({ id: "registerPage_welcome_title" })}
            </h1>
            <p className={styles.partnerSubTitle}>
              {" "}
              {intl.formatMessage({ id: "registerPage_welcome_subTitle" })}
            </p>
            <span className={styles.partnerRow}>
              <Flex
                flexDirection="column"
                alignItems="center"
                style={{ marginTop: "3%" }}
              >
                <Link
                  to={"/contactos"}
                  style={{ color: "white", textDecoration: "none" }}
                >
                  <Button primary>
                    {intl.formatMessage({
                      id: "registerPage_registerButton_label",
                    })}
                  </Button>
                </Link>
              </Flex>
            </span>
          </div>
        </div>
        <div className={styles.advantageHighlightsSection}>
          <div className={styles.advantageTitleCol}>
            <div>
              <h2 className={styles.advantageTitle}>
                {intl.formatMessage({ id: "registerPage_advantage_title" })}
              </h2>
              <p className={styles.advantageText}>
                {intl.formatMessage({ id: "registerPage_advantage_subTitle" })}
              </p>
            </div>
            <img
              className={styles.houseImage}
              src={data.house.publicURL}
              alt="house icon take-eat"
            />
          </div>
          <div className={styles.cardList}>
            {renderAdvantagesCards(advantages)}
          </div>
        </div>
        <div className={styles.featureHighlightsSection}>
          <div className={styles.featureCol}>
            <div>
              <RedFeature
                title={intl.formatMessage({
                  id: "registerPage_keyFeature_feature_one_title",
                })}
                text={intl.formatMessage({
                  id: "registerPage_keyFeature_feature_one_subTitle",
                })}
                icon={data.orderBadge?.publicURL}
                alt="order management badge"
              />
            </div>
            <Spacer size={2} direction="vertical" />
            <div>
              <RedFeature
                title={intl.formatMessage({
                  id: "registerPage_keyFeature_feature_two_title",
                })}
                text={intl.formatMessage({
                  id: "registerPage_keyFeature_feature_two_subTitle",
                })}
                icon={data.menuBadge?.publicURL}
                alt="menu setup badge"
              />
            </div>
          </div>
          <div className={styles.featureTablet}>
            <img
              className={styles.featureTabletImage}
              src={data.ipad?.publicURL}
              alt="ipad take-eat dashboard"
            />
          </div>
          <div className={styles.featureCol}>
            <div>
              <RedFeature
                title={intl.formatMessage({
                  id: "registerPage_keyFeature_feature_three_title",
                })}
                text={intl.formatMessage({
                  id: "registerPage_keyFeature_feature_three_subTitle",
                })}
                icon={data.stockBadge?.publicURL}
                alt="stock management badge"
              />
            </div>
            <Spacer size={2} direction="vertical" />
            <div>
              <RedFeature
                title={intl.formatMessage({
                  id: "registerPage_keyFeature_feature_four_title",
                })}
                text={intl.formatMessage({
                  id: "registerPage_keyFeature_feature_four_subTitle",
                })}
                icon={data.bellBadge?.publicURL}
                alt="bell badge"
              />
            </div>
          </div>
        </div>
        <div className={styles.contactFormSection}>
          <div className={styles.contactWrapper}>
            <TextCard>
              <ContactForm />
            </TextCard>
          </div>
          <img
            src={data.yellowSquares?.publicURL}
            alt="yellow squares background"
            className={styles.yellowSquares}
          />
          <div className={styles.contactBackgroundWrapper}>
            <img
              src={data.backgroundContact?.publicURL}
              alt="contact form background"
              className={styles.contactImagebackground}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
