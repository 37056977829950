import React, { FunctionComponent } from "react";
import styles from "./styles.module.scss";

interface RedFeatureProps {
  title?: string;
  text?: string;
  icon?: string;
  alt?: alt;
}
const RedFeature: FunctionComponent<RedFeatureProps> = ({ title, text, icon, alt}) => {
  return (
    <>
      <img
        src={icon}
        alt={alt}
        className={styles.icon}
      />
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.text}>
        {text}
      </p>
    </>
  )
};

export default RedFeature;
